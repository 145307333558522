<template>
  <div class="home">
    <div class="home_banner">
      <commonHeader style="position:absolute; z-index:99"></commonHeader>
      <el-carousel  arrow="never">
        <el-carousel-item v-for="(item, index) in bcSrc" :key="index">
          <img :src="item.src" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="center_plate">
      <div class="home_serve">
        <div class="serve_span" ref="serveSpan1">
          <span class="serve_title" ref="serveTitle">MAIN BUSINESS</span>
          <span class="serve_text" ref="serveText">定制化开发 高契合度行业方向</span>
        </div>
        <!-- <router-link class="nav_children_single" to="/aboutZC/company" :class="navChrActive === 1 ? 'nav_children_single_active' : 'nav_children_single'">公司简介</router-link> -->
        <div class="center_plate_o" ref="centerPlate">
          <router-link to="/human">
            <div class="center_plate_one">
              <div class="center_plate_mask">
                <img src="@/assets/homeSrc/home_main_icon1.png">
                <p>人力资源服务</p>
              </div>
            </div>
          </router-link>
          <div class="center_plate_box">
            <div class="center_plate_box1">
              <router-link to="/software/smartCity">
                <div class="center_plate_tow">
                  <div class="center_plate_mask">
                    <img src="@/assets/homeSrc/home_main_icon2.png">
                    <p>智慧城市</p>
                  </div>
                </div>
              </router-link>
              <router-link to="/software/blockchain">
                <div class="center_plate_three">
                  <div class="center_plate_mask">
                    <img src="@/assets/homeSrc/home_main_icon5.png">
                    <p>区块链</p>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="center_plate_box2">
              <router-link to="/software/Industrial">
                <div class="center_plate_four">
                  <div class="center_plate_mask">
                    <img src="@/assets/homeSrc/home_main_icon3.png">
                    <p>工业物联网</p>
                  </div>
                </div>
              </router-link>
              <router-link to="/software/supplychain">
                <div class="center_plate_five">
                  <div class="center_plate_mask">
                    <img src="@/assets/homeSrc/home_main_icon4.png">
                    <p>供应链金融</p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="serve_span" ref="serveSpan2">
          <span class="serve_title2" ref="serveTitle">CUSTOMER CASE</span>
          <span class="serve_text" ref="serveText">客户的肯定是我们最好的馈赠</span>
        </div>
        <div class="case_nav" ref="caseNav">
          <div :class="items.id === navId ? 'case_nav_single_active' : 'case_nav_single'" v-for="(items, index) in bannerList" :key="index" @click="getList(index)">
            <span>{{items.title}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="banner_bg" ref="bannerBg">
        <img src="@/assets/homeSrc/home_case_bg.jpg" style="width: 100%;height: 500px;">
      </div>
      <div class="banner_text" ref="bannerText">
        <p class="banner_text_title">{{bannerText.title}}</p>
        <p class="banner_text_box"></p>
        <p class="banner_text_info">{{bannerText.info2}}</p>
      </div>
      <div class="banner_list" ref="bannerList">
        <swiper :options="swiperOption" ref="mySwiper" class="swiper-wrapper swiper1" @slide-change-transition-end="handleClickSlide">
          <swiper-slide  v-for="items in bannerTextList" :key="items.id">
            <div class="swiper-slide_img">
              <img :src="items.src">
            </div>
            <div class="swiper-slide_text">
              <p class="swiper-slide_text_title">{{ items.title }}</p>
              <p class="swiper-slide_text_info">{{ items.info }}</p>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
    <div class="center_plate">
      <div class="home_serve">
        <div class="serve_span" ref="serveSpan3">
          <span class="serve_title3" ref="serveTitle">COMPANY PROFILE</span>
          <span class="serve_text" ref="serveText">提供专业行业解决方案</span>
        </div>
        <div class="company_profile" ref="companyProfile">
          <div class="company_profile_info">
            <p class="company_profile_info_text1">软件定制开发</p>
            <p class="company_profile_info_text2">·ABOUT US</p>
            <div class="company_profile_info_text">
              <p class="company_profile_info_text3">济南中曹软件服务有限公司</p>
              <p class="company_profile_info_text4"></p>
            </div>
            <p class="company_profile_info_text5">
              山东中曹软件服务有限公司（以下简称“中曹软件”）是中国领先的软件与信息技术服务商，
              打造一流的人力资源服务平台，致力于成为具有全国影响力的数字技术服务领导企业，企业数字化转型可信赖合作伙伴。
              2019年，公司成立于济南，服务全国市场。目前，员工30余人。
            </p>
            <router-link to="/aboutZC/company"><p class="company_profile_info_button">了解更多</p></router-link>
            <div class="company_profile_info_div">
              <div class="company_profile_info_div_single">
                <p class="company_profile_info_div_single_one">真实案例</p>
                <p class="company_profile_info_div_single_tow">50+</p>
              </div>
              <div class="company_profile_info_div_single">
                <p class="company_profile_info_div_single_one">合作伙伴</p>
                <p class="company_profile_info_div_single_tow">50+</p>
              </div>
              <div class="company_profile_info_div_single">
                <p class="company_profile_info_div_single_one" style="width: 102px;">员工人数</p>
                <p class="company_profile_info_div_single_tow">30+</p>
              </div>
            </div>
          </div>
          <div class="company_profile_bag"></div>
        </div>
        <div class="serve_span" ref="serveSpan4">
          <span class="serve_title4" ref="serveTitle">COOPERATIVE PARTNER</span>
          <span class="serve_text" ref="serveText">为您提供专业的软件开发服务</span>
        </div>
      </div>
    </div>
    <div class="company_partner">
      <div class="company_partner_swiper" ref="companyPartner">
        <swiper :options="swiperOption1" ref="mySwiper1">
          <swiper-slide class="swiper-wrapper"  v-for="items in footerList" :key="items.id">
            <div class="swiper-slide_imgs">
              <img :src="items.src" style="width:100%; height:100%">
            </div>
          </swiper-slide>
          <!-- <div class="swiper-button-prev1" slot="button-prev"></div>
          <div class="swiper-button-next1" slot="button-next"></div> -->
        </swiper>
      </div>
    </div>
    <footerBox/>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import commonHeader from '@/components/head'
import footerBox from '@/components/footer/foot_Index.vue'
export default {
  name: 'HomeView',
  components: {
    footerBox,
    commonHeader,
    swiper,
    swiperSlide
  },
  data () {
    return {
      // 第一个轮播配置
      swiperOption: {
        loop: true,
        slidesPerView: 3,
        loopedSlides: 6,
        spaceBetween: 30,
        centeredSlides: false,
        // 显示分页
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        pagination: '.swiper-pagination',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      // 第二个轮播配置
      swiperOption1: {
        loop: true,
        slidesPerView: 5,
        loopedSlides: 5,
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next1',
          prevEl: '.swiper-button-prev1'
        }
      },
      bcSrc: [
        { src: require('@/assets/homeSrc/banner-1.jpg') },
        { src: require('@/assets/homeSrc/banner-2.jpg') },
        { src: require('@/assets/homeSrc/banner-3.jpg') }
      ],
      bannerList: [
        {
          title: '邹城市人民医院',
          id: 1
        },
        {
          title: '海晟融资租赁业务',
          id: 2
        },
        {
          title: '爱尚悦读',
          id: 3
        },
        {
          title: '市北建投供应链',
          id: 4
        }
      ],
      navId: 1,
      bannerText: [],
      bannerTextList: [
        {
          title: '邹城市人民医院',
          src: require('@/assets/homeSrc/home_case_logo6.png'),
          info: '邹城市人民医院微信公众号为个人体检、单位体检提供体检咨询、预约、团购等体检一站式服务，让您享受更低且更优惠的套餐。',
          info2: 'ZOUCHENG PEOPLE HOSPITAL',
          id: 1
        },
        {
          title: '海晟融资租赁业务',
          src: require('@/assets/homeSrc/home_case_logo3.png'),
          info: '项目管理是提高租赁项目服务质量,防范租赁风险的一个重要环节,项目管理是给各租赁公司提供一个经营模式的参考。',
          info2: 'HAISHENG FINANCIAL LEASING BUSINESS',
          id: 2
        },
        {
          title: '爱尚悦读',
          src: require('@/assets/homeSrc/home_case_logo4.png'),
          info: '爱尚悦读管理系统是根据学生阅读时长来查看年级人均、班级人均阅读的一个平台，此平台中老师、年级主任和校长等多角色用户拥有不同权限查看学生阅读时长等。',
          info2: 'LOVE THE REACH TO READ',
          id: 3
        },
        {
          title: '市北建投供应链',
          src: require('@/assets/homeSrc/home_case_logo2.png'),
          info: '青岛市北建投供应链应链管理系统,支持进出口业务、境内外购销业务、仓储业务、 运输业务的操作管理,包含订单管理、仓储管理、项目审批、、结算管理、 财务管理、客户服务等子系统。',
          info2: 'SHIBEI CONSTRUCTION INVESTMENT SUPPLY CHAIN',
          id: 4
        }
      ],
      footerList: [
        {
          src: require('@/assets/homeSrc/home_partner1.png'),
          id: 1
        },
        {
          src: require('@/assets/homeSrc/home_partner2.png'),
          id: 2
        },
        {
          src: require('@/assets/homeSrc/home_partner3.png'),
          id: 3
        },
        {
          src: require('@/assets/homeSrc/home_partner4.png'),
          id: 4
        },
        {
          src: require('@/assets/homeSrc/home_partner5.png'),
          id: 5
        }
      ]
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    swiper1 () {
      return this.$refs.mySwiper1.swiper
    }
  },
  created () {
  },
  mounted () {
    this.bannerText = this.bannerTextList[0]
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleClickSlide () {
      console.log(this.swiper.realIndex)
      console.log(this.bannerTextList)
      this.navId = this.swiper.realIndex + 1
      this.bannerText = this.bannerTextList[this.swiper.realIndex]
    },
    getList (index) {
      console.log(index)
      this.navId = index + 1
      this.bannerText = this.bannerTextList[index]
      console.log(document.getElementsByClassName('swiper1')[0].firstElementChild.style)
      if (index === 0) {
        this.$nextTick(function () {
          document.getElementsByClassName('swiper1')[0].firstElementChild.style.webkitTransform = 'translate3d(-0px, 0px, 0px)'
          document.getElementsByClassName('swiper1')[0].firstElementChild.style.webkitTransitionDuration = '500ms'
        })
      } else if (index === 1) {
        this.$nextTick(function () {
          document.getElementsByClassName('swiper1')[0].firstElementChild.style.webkitTransform = 'translate3d(-450.33px, 0px, 0px)'
          document.getElementsByClassName('swiper1')[0].firstElementChild.style.webkitTransitionDuration = '500ms'
        })
      } else if (index === 2) {
        this.$nextTick(function () {
          document.getElementsByClassName('swiper1')[0].firstElementChild.style.webkitTransform = 'translate3d(-920px, 0px, 0px)'
          document.getElementsByClassName('swiper1')[0].firstElementChild.style.webkitTransitionDuration = '500ms'
        })
      } else if (index === 3) {
        this.$nextTick(function () {
          document.getElementsByClassName('swiper1')[0].firstElementChild.style.webkitTransform = 'translate3d(-1380px, 0px, 0px)'
          document.getElementsByClassName('swiper1')[0].firstElementChild.style.webkitTransitionDuration = '500ms'
        })
      }
      // console.log(document.getElementsByClassName('swiper1')[0].firstElementChild.className = name + ' lkk')
    },
    // 滚动触发动画
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.serveSpan1.offsetTop - 650) {
        if (this.$refs.serveSpan1.className === 'serve_span') {
          this.$refs.serveSpan1.className = this.$refs.serveSpan1.className + ' animated slideInRight'
          this.$refs.centerPlate.className = this.$refs.centerPlate.className + ' animated slideInLeft'
        }
      }
      if (__currentScroll >= this.$refs.serveSpan2.offsetTop - 750) {
        if (this.$refs.serveSpan2.className === 'serve_span') {
          this.$refs.serveSpan2.className = this.$refs.serveSpan2.className + ' animated fadeInUpBig'
          this.$refs.caseNav.className = this.$refs.caseNav.className + ' animated fadeInUpBig'
        }
      }
      if (__currentScroll >= this.$refs.bannerBg.offsetTop - 950) {
        if (this.$refs.bannerBg.className === 'banner_bg') {
          this.$refs.bannerBg.className = this.$refs.bannerBg.className + ' animated fadeInUpBig'
          this.$refs.bannerText.className = this.$refs.bannerText.className + ' animated slideInRight'
          this.$refs.bannerList.className = this.$refs.bannerList.className + ' animated slideInRight'
        }
      }
      if (__currentScroll >= this.$refs.serveSpan3.offsetTop - 722) {
        if (this.$refs.serveSpan3.className === 'serve_span') {
          this.$refs.serveSpan3.className = this.$refs.serveSpan3.className + ' animated fadeInUpBig'
          this.$refs.companyProfile.className = this.$refs.companyProfile.className + ' animated slideInLeft'
        }
      }
      if (__currentScroll >= this.$refs.serveSpan4.offsetTop - 700) {
        if (this.$refs.serveSpan4.className === 'serve_span') {
          this.$refs.serveSpan4.className = this.$refs.serveSpan4.className + ' animated fadeInUpBig'
          this.$refs.companyPartner.className = this.$refs.companyPartner.className + ' animated slideInRight'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
// 公共类名
.home{
  width: 100%;
  min-width: 1400px;
  overflow: hidden;
  .home_banner{
    width: 100%;
    height: 800px;
    img{
      position:absolute;
      width: 100%;
      height: 100%;
    }
    .el-carousel{
      width: 100%;
      height: 100%;
      ::v-deep.el-carousel__container{
        height: 100%!important;
        z-index: -1;
      }
      ::v-deep.el-carousel__button{
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 12px;
        }
    }
  }
  .center_plate {
    width: 1400px;
    overflow: hidden;
    margin: auto;
    .home_serve{
      width: 100%;
      margin-top: 61px;
      .serve_span{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
        overflow: hidden;
        text-align: center;
        .serve_title{
          font-family: MicrosoftYaHei;
          font-size: 60px;
          color: #f5f5f5;
          position: relative;
          font-weight: bold;
        }
        .serve_title::after {
          content: '中曹软件主营业务';
          background: url('~@/assets/homeSrc/home_title.png') no-repeat bottom center;
          height: 70px;
          line-height: 36px;
          position: absolute;
          font-weight: bold;
          color: #333;
          left: 104px;
          font-size: 36px;
        }
        .serve_title2{
          font-family: MicrosoftYaHei;
          font-size: 60px;
          color: #f5f5f5;
          position: relative;
          font-weight: bold;
        }
        .serve_title2::after {
          content: '中曹软件客户案例';
          background: url('~@/assets/homeSrc/home_title.png') no-repeat bottom center;
          height: 70px;
          line-height: 36px;
          position: absolute;
          font-weight: bold;
          color: #333;
          left: 107px;
          font-size: 36px;
        }
        .serve_title3{
          font-family: MicrosoftYaHei;
          font-size: 60px;
          color: #f5f5f5;
          position: relative;
          font-weight: bold;
        }
        .serve_title3::after {
          content: '中曹软件公司简介';
          background: url('~@/assets/homeSrc/home_title.png') no-repeat bottom center;
          height: 70px;
          line-height: 36px;
          position: absolute;
          font-weight: bold;
          color: #333;
          left: 148px;
          font-size: 36px;
        }
        .serve_title4{
          font-family: MicrosoftYaHei;
          font-size: 60px;
          color: #f5f5f5;
          position: relative;
          font-weight: bold;
        }
        .serve_title4::after {
          content: '中曹软件合作伙伴';
          background: url('~@/assets/homeSrc/home_title.png') no-repeat bottom center;
          height: 70px;
          line-height: 36px;
          position: absolute;
          font-weight: bold;
          color: #333;
          left: 219px;
          font-size: 36px;
        }
        .serve_text{
          width: 290px;
          font-family: MicrosoftYaHei;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 2px;
          color: #333333;
        }
      }
      .center_plate_o{
        width: 100%;
        height: 670px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        .center_plate_one{
          width: 340px;
          height: 100%;
          position: relative;
          background: url('~@/assets/homeSrc/home_main1.jpg') no-repeat center;
          .center_plate_mask{
            background-color: rgba(0,0,0,.65);
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              margin-top: 285px;
              margin-bottom: 20px;
            }
            p {
              font-family: MicrosoftYaHei;
              font-size: 26px;
              color: #f5f5f5;
            }
          }
        }
        .center_plate_one:hover{
          .center_plate_mask{
            background-color: #0773fc;
            img {
              margin-top: 200px;
              margin-bottom: 20px;
            }
            p {
              margin-bottom: 30px;
            }
          }
          .center_plate_mask::after{
            width: 280px;
            content: '针对企业辅助性和临时性岗位，公司提供从招聘到管理的外包服务，提供专业行业解决方案，降低人力招聘成本，提升公司的运营能力。';
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #f5f5f5;
          }
        }
        .center_plate_box{
          width: 1030px;
          height: 670px;
          .center_plate_box1{
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 320px;
            margin-bottom: 30px;
            .center_plate_tow{
              position: relative;
              width: 340px;
              height: 100%;
              background: url('~@/assets/homeSrc/home_main2.jpg') no-repeat center;
              .center_plate_mask{
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.65);
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  margin-top: 110px;
                  margin-bottom: 20px;
                }
                p {
                  font-family: MicrosoftYaHei;
                  font-size: 26px;
                  color: #f5f5f5;
                }
              }
            }
            .center_plate_tow:hover{
              .center_plate_mask{
                display: inherit;
                flex-direction: inherit;
                align-items: inherit;
                overflow: hidden;
                background-color: #0773fc;
                img {
                  float: left;
                  margin: 85px 20px 0px 50px;
                }
                p {
                  margin: 92px 0 0px 0;
                }
              }
              .center_plate_mask::after{
                width: 260px;
                content: '智慧城市把新一代信息技术充分运用在城市的各行各业之中，实现信息化、工业化与城镇化深度融合，促进社会可持续性和经济发展。';
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #f5f5f5;
                left: 50px;
                top: 160px;
                position: absolute;
              }
            }
            .center_plate_three{
              width: 660px;
              height: 100%;
              position: relative;
              background: url('~@/assets/homeSrc/home_main3.jpg') no-repeat center;
              .center_plate_mask{
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.65);
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  margin-top: 110px;
                  margin-bottom: 20px;
                }
                p {
                  font-family: MicrosoftYaHei;
                  font-size: 26px;
                  color: #f5f5f5;
                }
              }
            }
            .center_plate_three:hover{
              .center_plate_mask{
                display: inherit;
                flex-direction: inherit;
                align-items: inherit;
                overflow: hidden;
                background-color: #0773fc;
                img {
                  float: left;
                  margin: 85px 20px 0px 50px;
                }
                p {
                  margin: 92px 0 0px 0;
                }
              }
              .center_plate_mask::after{
                width: 555px;
                content: '区块链技术是互联网时代一种新的“信息传递”技术，是去中心化的分布式账本数据库。';
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #f5f5f5;
                left: 50px;
                top: 160px;
                position: absolute;
              }
            }
          }
          .center_plate_box2{
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 320px;
            .center_plate_four{
              position: relative;
              width: 660px;
              height: 100%;
              background: url('~@/assets/homeSrc/home_main4.jpg') no-repeat center;
              .center_plate_mask{
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.65);
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  margin-top: 110px;
                  margin-bottom: 20px;
                }
                p {
                  font-family: MicrosoftYaHei;
                  font-size: 26px;
                  color: #f5f5f5;
                }
              }
            }
            .center_plate_four:hover{
              .center_plate_mask{
                display: inherit;
                flex-direction: inherit;
                align-items: inherit;
                overflow: hidden;
                background-color: #0773fc;
                img {
                  float: left;
                  margin: 85px 20px 0px 50px;
                }
                p {
                  margin: 92px 0 0px 0;
                }
              }
              .center_plate_mask::after{
                width: 555px;
                content: '将具有感知、监控能力的各类采集、控制传感器或控制器，以及移动通信、智能分析等技术不断融入到工业生产过程各个环节，从而大幅提高制造效率，改善产品质量，降低产品成本和资源消耗，最终实现将传统工业提升到智能化的新阶段。';
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #f5f5f5;
                left: 50px;
                top: 160px;
                position: absolute;
              }
            }
            .center_plate_five{
              width: 340px;
              height: 100%;
              position: relative;
              background: url('~@/assets/homeSrc/home_main5.jpg') no-repeat center;
              .center_plate_mask{
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.65);
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  margin-top: 110px;
                  margin-bottom: 20px;
                }
                p {
                  font-family: MicrosoftYaHei;
                  font-size: 26px;
                  color: #f5f5f5;
                }
              }
            }
            .center_plate_five:hover{
              .center_plate_mask{
                display: inherit;
                flex-direction: inherit;
                align-items: inherit;
                overflow: hidden;
                background-color: #0773fc;
                img {
                  float: left;
                  margin: 85px 20px 0px 50px;
                }
                p {
                  margin: 92px 0 0px 0;
                }
              }
              .center_plate_mask::after{
                width: 260px;
                content: '银行围绕核心企业，管理上下游中小企业的资金流和物流，并把单个企业的不可控风险转变为供应链企业整体的可控风险，通过立体获取各类信息，将风险控制在最低的金融服务。';
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #f5f5f5;
                left: 50px;
                top: 160px;
                position: absolute;
              }
            }
          }
        }
      }
      .case_nav{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .case_nav_single{
          position: relative;
          height: 30px;
          font-family: MicrosoftYaHei;
          font-size: 18px;
          font-weight: bold;
          color: #666666;
          cursor: pointer;
        }
        .case_nav_single:hover{
          color: #0773fc;
          outline: none;
          cursor: pointer;
          &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0px;
            left: 18.5px;
            right: 20.5px;
            height: 2px;
            background-color: #0773fc;
          }
        }
        .case_nav_single_active{
          position: relative;
          height: 30px;
          font-family: MicrosoftYaHei;
          font-size: 18px;
          font-weight: bold;
          color: #0773fc;
          outline: none;
          cursor: pointer;
          &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0px;
            left: 18.5px;
            right: 20.5px;
            height: 2px;
            background-color: #0773fc;
          }
        }
      }
      .company_profile{
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        .company_profile_info{
          width: 650px;
          font-family: MicrosoftYaHei;
          .company_profile_info_text1{
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 3px;
            color: #333333;
            float: left;
            margin-right: 35px;
          }
          .company_profile_info_text2{
            font-size: 30px;
            font-weight: bold;
            color: #0773fc;
          }
          .company_profile_info_text{
            margin-top: 20px;
            margin-bottom: 50px;
            overflow: hidden;
            .company_profile_info_text3{
              font-size: 18px;
              color: #333333;
              float: left;
              margin-right: 25px;
            }
            .company_profile_info_text4{
              width: 400px;
              height: 1px;
              background-color: #999999;
              border-radius: 1px;
              float: left;
              margin-top: 12px;
            }
          }
          .company_profile_info_text5{
            width: 653px;
            font-size: 14px;
            color: #333333;
          }
          .company_profile_info_button{
            width: 120px;
            height: 40px;
            border-radius: 6px 8px 8px 8px;
            border: solid 1px #0773fc;
            text-align: center;
            line-height: 40px;
            color: #0773fc;
            margin: 40px 0 45px 0;
          }
          .company_profile_info_div{
            width: 824px;
            height: 132px;
            background: rgba(7,115,252,0.8);
            border-radius: 0px 65px 65px 0px;
            position: relative;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .company_profile_info_div_single{
              width: 116px;
              text-align: center;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              .company_profile_info_div_single_one{
                font-size: 18px;
              }
              .company_profile_info_div_single_tow{
                font-size: 48px;
                font-weight: bold;
              }
            }
          }
        }
        .company_profile_bag{
          width: 650px;
          height: 450px;
          background: url('~@/assets/homeSrc/home_about_bg.jpg') no-repeat center;
        }
      }
    }
  }
  .banner{
    width: 100%;
    height: 500px;
    margin-top: 30px;
    background-color: #1e2539;
    overflow: hidden;
    .banner_bg{
      position: relative;
      float: left;
      width: 50%;
    }
    .banner_text{
      float: left;
      width: 645px;
      height: 100px;
      margin: 40px 0 0 40px;
      font-family: MicrosoftYaHei;
      .banner_text_title{
        font-size: 22px;
        font-weight: bold;
        color: #ffffff;
      }
      .banner_text_box{
        width: 30px;
        height: 1px;
        background-color: #ffffff;
        margin: 20px 0 20px 0;
      }
      .banner_text_info{
        width: 644px;
        font-size: 20px;
        letter-spacing: 1px;
        color: #e2e2e2;
      }
    }
    .banner_list{
      width: 1360px;
      height: 280px;
      position: relative;
      top: 220px;
      left: 600px;
      .swiper-container {
        position: absolute;
        width: 100%;
        height: 100%;
        .swiper-button-next {
          border-radius: 6px;
          color: #f5f5f5;
          position: absolute;
          top: 240px;
          left: 34%;
          border: 1px solid #fff;
          width: 30px;
          height: 30px;
          background-position: 1000px 1000px;
          background-color: rgba(0,0,0,.2);
        }
        .swiper-button-next:active {
          background-color: rgba(0,0,0,.9);
          color: #fff;
        }
        .swiper-button-next::after{
          content: '>';
          font-size: 26px;
          text-align: center;
          display: inline-block;
          width: 100%;
          line-height: 30px;
        }
        .swiper-button-prev {
          border-radius: 6px;
          position: absolute;
          color: #f5f5f5;
          left: 30%;
          top: 240px;
          border: 1px solid #fff;
          width: 30px;
          height: 30px;
          background-position: 1000px 1000px;
          background-color: rgba(0,0,0,.2);
        }
        .swiper-button-prev::after{
          content: '<';
          font-size: 26px;
          text-align: center;
          display: inline-block;
          width: 100%;
          line-height: 30px;
        }
        .swiper-button-prev:active {
          background-color: rgba(0,0,0,.9);
          color: #fff;
        }
        .swiper-slide {
          display: flex;
          border-radius: 8px;
          background-color: #fff;
          width: 435px!important;
          height: 165px;
          .swiper-slide_img{
            margin: 30px 20px 0 20px;
            img{
              border: 1px solid #cccccc;
              border-radius: 8px;
            }
          }
          .swiper-slide_text{
            display: flex;
            flex-direction: column;
            font-family: MicrosoftYaHei;
            .swiper-slide_text_title{
              font-size: 18px;
              margin: 24px 0 10px 0;
              letter-spacing: 1px;
              color: #0773fc;
            }
            .swiper-slide_text_info{
              width: 285px;
              font-size: 14px;
              letter-spacing: 1px;
              color: #333333;
            }
          }
        }
      }
    }
  }
  .company_partner{
    width: 1510px;
    height: 170px;
    margin: auto;
    overflow: hidden;
    .company_partner_swiper{
      width: 1485px;
      height: 100%;
      margin: auto;
      .swiper-container {
        position: absolute;
        width: 1485px;
        .swiper-wrapper{
          margin-left: 12px;
          .swiper-slide {
            display: flex;
            width: 265px!important;
            height: 165px;
            background: #fff;
            border: solid 1px #e5e5e5;
            margin-right: 18.5px;
          }
          .swiper-slide:hover {
            border: dashed 1px #e5e5e5;
          }
        }
        .swiper-button-next1 {
          border-radius: 50px;
          position: absolute;
          top: 55px;
          z-index: 1;
          width: 45px;
          height: 45px;
          background: url('~@/assets/homeSrc/home_case_left.png') no-repeat center;
          background-color: rgba(0,0,0,.4);
        }
        .swiper-button-next1:active {
          background-color: rgba(0,0,0,.7);
        }
        .swiper-button-prev1 {
          border-radius: 50px;
          position: absolute;
          top: 55px;
          right: 0;
          z-index: 1;
          width: 45px;
          height: 45px;
          background: url('~@/assets/homeSrc/home_case_right.png') no-repeat center;
          background-color: rgba(0,0,0,.4);
        }
        .swiper-button-prev1:active {
          background-color: rgba(0,0,0,.7);
        }
      }
    }
  }
}
</style>
